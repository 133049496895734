import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

const themeClassNames = {
  white: 'bg-white shadow-xl',
  gray: 'bg-gray-7',
};

const CardWithIcon = ({ icon: Icon, title, description, linkUrl, linkText, theme, linkTheme }) => {
  const className = classNames(
    'flex flex-col sm:flex-row sm:space-x-6 lg:space-x-0 lg:flex-col p-8 text-left bg-white rounded-2xl',
    themeClassNames[theme]
  );
  const isShowLink = linkUrl && linkText;
  return (
    <div className={className}>
      <Icon className="flex-shrink-0" />
      <div className="flex flex-col h-full">
        <Heading className="mt-6 sm:mt-0 lg:mt-6" tag="h3" size="sm" asHTML>
          {title}
        </Heading>
        <p className={`mt-2.5 ${isShowLink && 'mb-5'}`}>{description}</p>
        {isShowLink && (
          <Link className="mt-auto" type="arrow" theme={linkTheme} to={linkUrl}>
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
};

CardWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.func.isRequired,
  linkTheme: PropTypes.string,
};

CardWithIcon.defaultProps = {
  linkUrl: null,
  linkText: null,
  theme: 'gray',
  linkTheme: 'primary-1',
};

export default CardWithIcon;
