import React from 'react';

import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const title = 'Benefits of Yobi’s Platform';
const items = [
  {
    title: 'Revenue Optimization',
    description:
      'Enhance the value of your ad inventory and dramatically increase revenue by extracting all potential engagement and profit from customers',
  },
  {
    title: 'Precise Ad Targeting',
    description:
      'Improve the effectiveness of advertising by incorporating accurate hyper-targeting and predictions of customer intent across marketing channels',
  },
  {
    title: 'Powerful Insights',
    description:
      'Combine your first party data with Yobi’s rich dataset to create an unparalleled unified view of consumer behavior',
  },
  {
    title: 'Privacy Guaranteed ',
    description:
      'Earn customer trust and rapidly maximize technology and revenue capabilities in a privacy preserving environment',
  },
];

const Benefits = () => (
  <section className="relative pt-24 pb-24 lg:pt-32 lg:pb-32 xl:pb-40 gradient-background-gray">
    <Container>
      <Heading className="text-center" tag="h2" size="lg">
        {title}
      </Heading>

      <div className="grid grid-cols-1 gap-8 mt-10 lg:mt-16 md:grid-cols-2">
        {items.map(({ title, description }, index) => (
          <CardWithNumber title={title} description={description} index={index} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default Benefits;
