import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import HeroWithImage from 'components/shared/hero-with-image';

const title = 'Democratizing Data-Driven Behavioral Science';
const description =
  'Yobi has produced the first dynamic, privacy preserving AI model of consumer behavior. Our representations can be utilized to improve customer experience, advertising performance, insights, and modeling.';

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pages/marketing-advertising/hero/image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 710)
        }
      }
    }
  `);
  return <HeroWithImage title={title} description={description} image={image} />;
};

export default Hero;
