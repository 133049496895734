import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Field from 'components/shared/field';
import FormButton from 'components/shared/form-button';

const FORM_NAME = 'request-demo';
const BUTTON_SUCCESS_TIMEOUT_MS = 1.5 * 1000;

const validationSchema = yup.object().shape({
  email: yup.string().email('Please provide a valid email').required('Email is a required field'),
});

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const RequestDemo = ({ className: additionalClassName }) => {
  const className = classNames(
    'flex flex-col space-y-2.5 xs:space-y-0 xs:flex-row',
    additionalClassName
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const [buttonState, setButtonState] = useState('default');

  const onSubmit = (values) => {
    setButtonState('loading');
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': FORM_NAME,
        ...values,
      }),
    })
      .then(() => {
        reset({ email: '' });
        setButtonState('success');
        setTimeout(() => {
          setButtonState('default');
        }, BUTTON_SUCCESS_TIMEOUT_MS);
        console.log('Form successfully submitted');

        // it's pretty safe at this point to expect yopix to be loaded and to throw an exception even if it isn't, but
        // for the sake of a clean console, let's check first
        if (window.yopix) {
          window.yopix('event', 'demo-requested', values);
        }
      })
      .catch((error) => {
        setButtonState('default');
        console.log(error.message);
      });
  };

  return (
    <form
      className={className}
      data-netlify="true"
      name={FORM_NAME}
      method="post"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input name="form-name" type="hidden" value={FORM_NAME} />
      {/* hidden field to filter out bot users
            https://docs.netlify.com/forms/spam-filters/ */}
      <input name="bot-field" type="hidden" />
      <Field
        type="email"
        placeholder="Your work email..."
        theme="primary"
        error={errors?.email?.message}
        {...register('email')}
      />
      <FormButton
        state={buttonState}
        theme="primary"
        className="justify-center flex-shrink-0 w-full xs:w-48 xs:ml-2.5"
      >
        Request Demo
      </FormButton>
    </form>
  );
};

RequestDemo.propTypes = {
  className: PropTypes.string,
};

RequestDemo.defaultProps = {
  className: null,
};

export default RequestDemo;
