import React from 'react';

import Benefits from 'components/pages/marketing-advertising/benefits';
import Features from 'components/pages/marketing-advertising/features';
import Hero from 'components/pages/marketing-advertising/hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const MarketingAdvertising = () => (
  <MainLayout pageMetadata={SeoMetadata['marketing-advertising']}>
    <Hero />
    <Benefits />
    <Features />
  </MainLayout>
);

export default MarketingAdvertising;
