import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';

const themeClassNames = {
  white: 'bg-white shadow-xl',
  gray: 'bg-gray-7',
};

const CardWithNumber = ({ title, description, index, theme }) => {
  const className = classNames('relative rounded-2xl py-7 pr-8 pl-20', themeClassNames[theme]);
  const cardNumber = index + 1;
  return (
    <div className={className}>
      <div className="absolute top-0 left-0 flex items-center justify-center w-12 h-full">
        <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-12 h-12 text-2xl font-black text-white bg-primary-2 rounded-tl-2xl rounded-br-2xl">
          {cardNumber}
        </div>
        <div className="absolute top-0 w-2 h-full opacity-30 bg-gradient-to-b from-primary-2 " />
      </div>
      <Heading className="mb-2.5" tag="h3" size="sm">
        {title}
      </Heading>
      <p>{description}</p>
    </div>
  );
};

CardWithNumber.propTypes = {
  theme: PropTypes.oneOf(['white', 'gray']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

CardWithNumber.defaultProps = {
  theme: 'white',
};

export default CardWithNumber;
