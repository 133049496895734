import { yupResolver } from '@hookform/resolvers/yup';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Container from 'components/shared/container';
import Field from 'components/shared/field';
import FormButton from 'components/shared/form-button';
import Heading from 'components/shared/heading';
import encode from 'utils/form-encode';

const FORM_NAME = 'contact-us';
const BUTTON_SUCCESS_TIMEOUT_MS = 1.5 * 1000;

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  email: yup.string().email('Please provide a valid email').required('Email is a required field'),
  message: yup.string().required('Message is a required field'),
});

const ContactForm = () => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "shared/contact-form/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 608)
        }
      }
    }
  `);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const [buttonState, setButtonState] = useState('default');

  const onSubmit = (values) => {
    setButtonState('loading');
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': FORM_NAME,
        ...values,
      }),
    })
      .then(() => {
        reset({ email: '', name: '', message: '' });
        setButtonState('success');
        setTimeout(() => {
          setButtonState('default');
        }, BUTTON_SUCCESS_TIMEOUT_MS);

        // it's pretty safe at this point to expect yopix to be loaded and to throw an exception even if it isn't, but
        // for the sake of a clean console, let's check first
        if (window.yopix) {
          window.yopix('event', 'contact-form-submitted', {
            name: values.name,
            email: values.email,
          });
        }
      })
      .catch((error) => {
        setButtonState('default');
      });
  };

  return (
    <section id="request-demo" className="relative pt-4 mt-20 lg:mt-28 xl:mt-36 -mb-80">
      <Container>
        <div className="flex flex-col overflow-hidden lg:flex-row xl:grid xl:grid-cols-2 rounded-2xl">
          <div className="relative flex-1 bg-gray-1 lg:gradient-background-contact-form">
            <Heading
              className="px-6 pt-10 pb-0 xs:px-10 lg:p-14 xl:p-20"
              tag="h2"
              size="xl"
              theme="white"
            >
              Let's start a conversation.
              <br /> Get in touch.
            </Heading>
            <div className="hidden lg:inline-block lg:!absolute bottom-0 inset-x-0">
              <GatsbyImage image={getImage(illustration)} alt="" aria-hidden />
            </div>
          </div>
          <form
            className="flex-1 px-6 pt-0 pb-10 xs:px-10 lg:p-14 xl:p-20 bg-gray-1 space-y-9"
            data-netlify="true"
            name={FORM_NAME}
            method="post"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input name="form-name" type="hidden" value={FORM_NAME} />
            {/* hidden field to filter out bot users
            https://docs.netlify.com/forms/spam-filters/ */}
            <input name="bot-field" type="hidden" />
            <Field
              id="contact-form-name"
              label="Name"
              type="text"
              placeholder="Your name"
              theme="secondary"
              error={errors?.name?.message}
              {...register('name')}
            />
            <Field
              id="contact-form-email"
              label="Email"
              type="email"
              placeholder="Work email address"
              theme="secondary"
              error={errors?.email?.message}
              {...register('email')}
            />
            <Field
              id="contact-form-message"
              label="Message"
              type="textarea"
              rows="3"
              placeholder="A message"
              theme="secondary"
              error={errors?.message?.message}
              {...register('message')}
            />
            <div className="flex flex-col justify-between space-y-4 lg:items-center lg:space-x-2 lg:space-y-0 lg:flex-row">
              <p className="text-sm font-medium text-white lg:max-w-[235px]">
                By submitting this form, you agree to receive emails from Yobi.
              </p>
              <FormButton
                state={buttonState}
                theme="white"
                className="justify-center flex-shrink-0 w-full ml-auto md:w-32 md:self-start lg:ml-0"
              >
                Submit
              </FormButton>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default ContactForm;
