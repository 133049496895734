import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import RequestDemo from 'components/shared/request-demo';

const textSizeClassNames = {
  '2xl': 'text-xl lg:text-2xl',
  xl: 'text-xl',
};

const HeroWithImage = ({ title, description, textSize, image, hasDarkBg }) => {
  const descriptionClassName = classNames(
    'mt-5 lg:max-w-[470px] leading-normal',
    textSizeClassNames[textSize]
  );
  const wrapperStyles = hasDarkBg
    ? 'relative overflow-hidden before:none lg:before:absolute before:right-0 before:top-0 before:w-1/2 before:h-full before:bg-secondary-1 before:translate-x-48 mt-12 mb-20 sm:mb-0 lg:mt-0'
    : '';
  return (
    <section className={wrapperStyles}>
      <Container className="flex flex-col lg:flex-row">
        <div className="flex flex-col justify-center flex-1 lg:pb-8 lg:mr-4 xl:pb-0 lg:w-1/2 xl:w-[592px] xl:-mr-18">
          <Heading tag="h1" size="xl">
            {title}
          </Heading>
          <p className={descriptionClassName}>{description}</p>
          <RequestDemo className="mt-8 md:max-w-[592px]" />
        </div>
        <div className="hidden mt-12 -mx-4 text-center sm:block lg:mt-0 lg:ml-4 lg:-mr-6 lg:w-1/2 xl:w-auto xl:ml-48 xl:-mr-48 bg-secondary-1 md:-mx-6 lg:m-0 lg:flex lg:items-center lg:justify-center">
          <GatsbyImage
            className="max-w-[320px] md:max-w-[500px] lg:max-w-full 2xl:left-[5vw]"
            image={getImage(image)}
            alt={title}
            loading="eager"
          />
        </div>
      </Container>
    </section>
  );
};

HeroWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textSize: PropTypes.oneOf(['2xl', 'xl']),
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  hasDarkBg: PropTypes.bool,
};

HeroWithImage.defaultProps = {
  hasDarkBg: true,
  textSize: 'xl',
};

export default HeroWithImage;
