import React from 'react';

import Container from 'components/shared/container';
import Link from 'components/shared/link';
import Logo from 'images/logo.inline.svg';

const items = [
  {
    label: 'Platform',
    items: [
      {
        label: 'Intelligence',
        to: '/intelligence',
      },
      {
        label: 'Growth',
        to: '/growth',
      },
      {
        label: 'Personalization',
        to: '/personalization',
      },
    ],
  },
  {
    label: 'Solutions',
    items: [
      {
        label: 'Marketing',
        to: '/marketing-advertising',
      },
      {
        label: 'Data science',
        to: '/data-science',
      },
      {
        label: 'Data monetization',
        to: '/data-monetization',
      },
      {
        label: 'Finance',
        to: '/finance',
      },
    ],
  },
  {
    label: 'Technology',
    items: [
      {
        label: 'Behavior genome',
        to: '/behavior-genome',
      },
    ],
  },
  {
    label: 'Company',
    items: [
      {
        label: 'About',
        to: '/about',
      },
    ],
  },
];

const Footer = () => (
  <footer className="bg-black pt-[400px] pb-18">
    <Container>
      <div className="grid grid-cols-8 text-white gap-y-8 lg:grid-cols-10 xl:grid-cols-12">
        <div className="col-span-8 lg:col-span-2 xl:col-span-4">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        {items.map(({ label, items }, index) => (
          <div className="col-span-8 xs:col-span-4 md:col-span-2" key={index}>
            <h3 className="text-sm font-bold tracking-wider uppercase text-gray-3">{label}</h3>
            <ul className="mt-6 space-y-6">
              {items.map(({ label, to }, index) => (
                <li key={index}>
                  <Link className="block" to={to} type="text" theme="white">
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="flex flex-col justify-between pt-8 space-y-4 leading-none border-t sm:space-y-0 sm:flex-row text-gray-3 border-t-gray-1 mt-14">
        <p>
          <Link to="https://docs.google.com/document/d/1ilV9ewHfOQihkmvAs-ejFKiVR7ZLg6cgxZIBcm31_es/edit" type="text" theme="primary-2" target="_blank">
          Privacy Policy
          </Link>
          <br />
          <Link to="https://forms.gle/xnUJVD9WLzWAXo456" type="text" theme="primary-2" target="_blank">
          Do Not Sell My Data / Opt Out
          </Link>
        </p>
        <p>© 2023 Yobi Ventures. All rights reserved.</p>
      </div>
    </Container>
  </footer>
);

export default Footer;
