import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import APIIcon from './images/api.inline.svg';
import DataIcon from './images/data.inline.svg';
import PlatformIcon from './images/platform.inline.svg';

const title = 'Fully Customizable';
const items = [
  {
    icon: DataIcon,
    title: 'Data',
    description:
      'Extract Yobi’s pre-processed, cleansed predictive representations for in-house modeling and activation',
  },
  {
    icon: PlatformIcon,
    title: 'Platform',
    description:
      'Navigate Yobi’s easy to use platform to access comprehensive data visualizations of customer behavior and market performance',
  },
  {
    icon: APIIcon,
    title: 'API',
    description: 'Coming soon...',
  },
];

const Features = () => (
  <section className="mt-28 lg:mt-32 xl:mt-40">
    <Container className="text-center">
      <Heading className="text-center" tag="h2" size="lg">
        {title}
      </Heading>

      <div className="grid grid-cols-1 gap-8 mt-10 lg:mt-16 lg:grid-cols-3">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon title={title} description={description} icon={Icon} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default Features;
